import React, { useEffect, useState, useRef } from "react";
import Modal from "./Modal";
import { Tooltip } from "react-tooltip";
import convertToCapitalSpaceCase from "../utils/convertToCapitalSpaceCase";

function timeAgo(fileTimeUtc) {
  const epochDiffMs = 11644473600000;
  const utcTimestampMs = fileTimeUtc / 10000 - epochDiffMs; // Convert to milliseconds and adjust for epoch difference
  const secondsPast = Math.floor(
    (new Date() - new Date(utcTimestampMs)) / 1000
  );
  //const secondsPast = Math.floor((new Date() - new Date(utcTimestamp)) / 1000);

  if (secondsPast < 60) {
    return `${secondsPast} s ago`;
  }
  if (secondsPast < 3600) {
    return `${Math.floor(secondsPast / 60)} m ago`;
  }
  if (secondsPast <= 86400) {
    return `${Math.floor(secondsPast / 3600)} h ago`;
  }
  if (secondsPast <= 2592000) {
    return `${Math.floor(secondsPast / 86400)} D ago`;
  }
  if (secondsPast <= 31536000) {
    return `${Math.floor(secondsPast / 2592000)} M ago`;
  }
  if (secondsPast > 31536000) {
    return `${Math.floor(secondsPast / 31536000)} Y ago`;
  }
  return `${Math.floor(secondsPast / 31536000)} Y ago`;
}

/* component to display recursive project structure */
const sampleProject = {
  owner: "0xdaa070D909E010211606144eDe5B2ca6864C2c1c",
  name: "cora",
  files: [
    {
      name: "nodes.csv",
      size: 11740825,
    },
    {
      name: "README.MD",
      size: 1550,
    },
    {
      name: "edges.csv",
      size: 250009,
    },
  ],
  folders: [
    {
      name: "subfolder",
      files: [
        {
          name: "cora.tgz",
          size: 168052,
        },
      ],
      folders: [],
    },
  ],
};

function TileTowardsMouse2D({ text }) {
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const radians = Math.atan2(clientY - centerY, clientX - centerX);
      const degree = radians * (180 / Math.PI);
      setAngle(degree);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <span
      style={{
        fontSize: "10rem",
        display: "inline-block",
        transform: `rotate(${angle}deg)`,
      }}
    >
      {text}
    </span>
  );
}

function TileTowardsMouse({ text }) {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [shadow, setShadow] = useState({ x: 0, y: 0 });
  const [gradientPosition, setGradientPosition] = useState({ x: 50, y: 50 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const xRotation = -(window.innerHeight / 2 - clientY) / 15;
      const yRotation = (window.innerWidth / 2 - clientX) / 15;
      setRotation({ x: xRotation, y: yRotation });

      const xShadow = (window.innerWidth / 2 - clientX) / 10;
      const yShadow = (window.innerHeight / 2 - clientY) / 10;
      setShadow({ x: xShadow, y: yShadow });

      const xPosition = (clientX / window.innerWidth) * 100;
      const yPosition = (clientY / window.innerHeight) * 100;
      setGradientPosition({ x: xPosition, y: yPosition });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <span
      style={{
        fontSize: "10vmin",
        display: "inline-block",
        perspective: "1000px",
        transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
        transition: "transform 0.1s",
        textShadow: `${shadow.x}px ${shadow.y}px 2px rgba(0,0,0,0.1)`,
      }}
    >
      {text}
    </span>
  );
}

// export default UserProjectView;

/*
nodeAction = {
connectionId: ""data: 
""description: "Analyze and report on innovation strategies, evaluating their impact on business growth and competitiveness.
"fileTypes: ".txt, .csv, .json, .xml, .html, .md"
g:  "StrategicAnalysis"
name: "InnovationStrategyAnalysis"
owner: ""
providerNodeId: "a58fc8db8cb5439d950fe1d261c07b77"
resource: ""
sign: ""
time: 1718012705
}
*/
const NodeActionsDropDown = ({
  nodeActions,
  onSelectedAction,
  onSelectResource,
  resource,
}) => {
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [filter, setFilter] = React.useState("");
  const [isFiltering, setIsFiltering] = useState(false);

  function filterNodeActions(resource, allNodeActions) {
    const fileType = resource.split(".").pop();
    //console.log("fileType", fileType);

    return allNodeActions.filter((action) => {
      const isSupported = action.fileTypes.includes(fileType);
      //console.log("action.fileTypes", action.fileTypes);
      return isSupported;
    });
  }
  function displayStars(num) {
    if (typeof num !== "number" || num <= 0) {
      return "";
      //return "Invalid input. Please enter a non-negative number.";
    }
    return "*"; //"⭐".repeat(num);
  }

  // extract resource file type
  const filteredActions = filterNodeActions(resource, nodeActions);

  const groupedNodeActions = nodeActions.reduce((groups, action) => {
    const key = action.g;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(action);
    return groups;
  }, {});

  useEffect(() => {
    if (!filter) {
      setIsFiltering(false);
      return;
    }
    // Flatten the groupedNodeActions into a single array
    const allActions = Object.values(groupedNodeActions).flat();
    //console.log("allActions", allActions);

    // Find the first action that includes the filter text
    const firstMatchingAction = allActions.find(
      (action) =>
        action.description.includes(filter) || action.name.includes(filter)
    );
    //console.log("firstMatchingAction", firstMatchingAction);

    // If a matching action is found, set it as the selected action
    // if (firstMatchingAction) {
    //   console.log("firstMatchingAction", firstMatchingAction);
    //   onSelectedAction(firstMatchingAction);
    //   setSelectedAction(firstMatchingAction);
    // } else {
    //   console.log("not firstMatchingAction", firstMatchingAction);
    //   onSelectedAction(null);
    //   setSelectedAction(null);
    // }
  }, [filter, groupedNodeActions, onSelectedAction, isFiltering]);
  // Set isFiltering to true when the user is typing in the filter
  const handleFilterChange = (event) => {
    setIsFiltering(true);
    setFilter(event.target.value);
    //console.log("change in filter", event.target.value, isFiltering);
  };

  // Set isFiltering to false when the user selects an option from the dropdown
  const handleSelectChange = (event) => {
    setIsFiltering(false);
    // rest of your select change handler code...
  };

  return (
    <>
      {/* Invoke */}
      <input
        type="text"
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
          handleFilterChange(e);
        }}
        placeholder="Filter actions"
        style={{ height: "0.2rem" }}
      />
      <br />
      <select
        style={{ width: "80vw", display: "block" }}
        //defaultValue={0}
        onChange={(e) => {
          handleSelectChange(e);
          if (e.target.value === "") {
            onSelectedAction(null);
            return;
          }
          // console.log(
          //   "keys of groupedNodeActions",
          //   Object.keys(groupedNodeActions)
          // );
          //console.log("target", e.target.value);

          const selectedGroup = Object.keys(groupedNodeActions).find((group) =>
            groupedNodeActions[group].some(
              (action) => action.name === e.target.value
            )
          );
          //console.log("selectedGroup", selectedGroup);
          const selectedAction = groupedNodeActions[selectedGroup].find(
            (action) => action.name === e.target.value
          );
          //console.log("selectedAction", selectedAction);
          // //onSelectedAction(filteredActions[e.target.value]);

          onSelectedAction(selectedAction); // select from nodeAction that is same as selected action
          setSelectedAction(selectedAction);
        }}
      >
        {/* <option value="">Select action</option> */}
        {Object.keys(groupedNodeActions)
          .filter(
            (g) =>
              g.toLowerCase().includes(filter.toLowerCase()) ||
              groupedNodeActions[g].some(
                (action) =>
                  action.description.includes(filter) ||
                  action.name.includes(filter)
              )
          )
          .map((g, groupIndex) => (
            <optgroup
              key={groupIndex}
              label={convertToCapitalSpaceCase(g)}
              className="my-optgroup"
            >
              {groupedNodeActions[g]
                .filter(
                  (action) =>
                    g.toLowerCase().includes(filter.toLowerCase()) || // If group name matches, include all actions
                    action.description.includes(filter) ||
                    action.name.includes(filter)
                )
                .map((action, actionIndex) => (
                  <option key={actionIndex} value={action.name}>
                    {convertToCapitalSpaceCase(
                      action.name.replace("Reporting", "")
                    )}{" "}
                    {displayStars(action.sc)}
                    {/* {action.description} */}
                  </option>
                ))}
            </optgroup>
          ))}
      </select>
      <div
        style={{
          // width: "40rem",
          marginRight: "5px",
          marginLeft: "5px",
          marginTop: "5px",
        }}
      >
        <strong>{convertToCapitalSpaceCase(selectedAction?.name)} </strong>
        &nbsp;
        {selectedAction?.description}
        <br />
        <small>
          <i>{selectedAction?.fileTypes}</i>
        </small>
      </div>{" "}
    </>
  );
};

// Component to display a file
const File = ({
  file,
  formatBytes,
  level,
  onGetFile,
  onSelectResource,
  onSelectInvokeAction,
  onDragFileToFolder,
  onPolinateResource,
  onCopyToContents,
  onGetResourceContents,
  onDeleteFile,
  onAddToSelectionList,
  selectionList,
  onActivateContextMenu,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  //console.log("file", file);
  const isSelected = selectionList.some(
    (item) => item.res === file.path + "/" + file.name
  );
  const fileStyle = {
    color: isSelected ? "green" : "inherit",
  };
  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow for dropping
    setIsDragOver(true); // Highlight the row
    //console.log("over file", e.target.id);
    // const dropedData = e.dataTransfer.getData("text/plain"); // Handle the dropped item, e.g., move or copy it to the current file's location
    // console.log("dropedData", dropedData);
  };

  const handleDragLeave = (e) => {
    setIsDragOver(false); // Remove the highlight
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const dropedData = e.dataTransfer.getData("text/plain"); // Handle the dropped item, e.g., move or copy it to the current file's location
    try {
      const nodeAction = JSON.parse(dropedData);

      if (nodeAction.g) {
        //console.log("action", nodeAction);
        onSelectInvokeAction(file.path + "/" + file.name, nodeAction);
      } else {
        console.log("fileOnfile", nodeAction);
        // file was droped on another file
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDragStart = (e, file) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(file));
    console.log("dragging", e.target.id, file);
  };

  return (
    <div
      className={`resource-highlite-row ${
        isDragOver ? "resource-highlite-ready-drop" : ""
      }`}
      style={{
        display: "flex",
        marginLeft: `${level * 0}px`,
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <span
        style={{ cursor: "pointer", ...fileStyle }}
        onClick={() => onAddToSelectionList(file.path + "/" + file.name)}
      >
        {isSelected ? "☑️" : "☐"}
      </span>
      &nbsp;
      <span
        style={{ cursor: "pointer", ...fileStyle }}
        onClick={() => onGetResourceContents(file.path + "/" + file.name)}
        onDragStart={(e) => handleDragStart(e, file)}
        draggable="true"
      >
        {file.name}
      </span>
      &nbsp;
      <span
        id="spaces-analysis"
        style={{ cursor: "pointer", ...fileStyle }}
        onClick={() => onSelectResource(file.path + "/" + file.name)}
      >
        🤖
      </span>
      {/* <span
        id="spaces-analysis"
        style={{ cursor: "pointer", ...fileStyle }}
        onClick={() => onSelectResource(file.path + "/" + file.name)}
      >
        {file.name}
      </span> */}
      &nbsp;
      <div style={{ float: "right", right: "0.5rem", position: "absolute" }}>
        <span className="resource-not-important">
          &nbsp;{timeAgo(file.modified)}&nbsp;
        </span>
        <span className="resource-not-important">
          &nbsp;{formatBytes(file.size)}&nbsp;&nbsp;
        </span>
        {/* <span
          id="spaces-delete"
          style={{ cursor: "pointer", fontSize: "0.9em" }}
          className="spaces-delete"
          onClick={() => onDeleteFile(file.path + "/" + file.name)}
        >
          ✕
        </span> */}
        &nbsp;
        <span
          id="spaces-menu"
          style={{ cursor: "pointer", fontSize: "0.9em" }}
          className="spaces-menu"
          onClick={(e) => onActivateContextMenu(e, file)}
        >
          ⋮
        </span>
      </div>
    </div>
  );
};

// Component to display a folder
const Folder = ({
  folder,
  formatBytes,
  level = 0,
  onGetFile,
  onSelectResource,
  onSelectInvokeAction,
  onGetResourceContents,
  onDragFileToFolder,
  onDeleteFile,
  onAddToSelectionList,
  filterText,
  selectionList,
  onActivateContextMenu,
  show = true,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow for dropping
    setIsDragOver(true); // Highlight the row
    //console.log("over folder", e.target.id);
  };
  const handleDragLeave = (e) => {
    setIsDragOver(false); // Remove the highlight
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const dropedData = e.dataTransfer.getData("text/plain"); // Handle the dropped item, e.g., move or copy it to the current file's location

    try {
      const file = JSON.parse(dropedData);
      console.log("fileOnFolder", file);
      onDragFileToFolder(file.path + "/" + file.name, folder.path);
      //onSelectInvokeAction(file.path + "/" + file.name, nodeAction);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [isFolded, setIsFolded] = useState(folder.fold);

  return (
    <div style={{ marginLeft: `${level * 2 + 5}px` }}>
      {show === true && (
        <>
          <span
            style={{ fontWeight: "bolder", cursor: "pointer" }}
            onClick={() => setIsFolded(!isFolded)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`resource-highlite-row ${
              isDragOver ? "resource-highlite-ready-drop" : ""
            }`}
          >
            {isFolded ? "📂" : "🗃"} {folder.name}
          </span>

          <span
            id="spaces-delete"
            style={{ cursor: "pointer", fontSize: "0.9em", marginLeft: "10px" }}
            className="spaces-delete"
            onClick={() => {
              onDeleteFile(folder.path);
              console.log("folder", folder);
            }}
          >
            ✕
          </span>
        </>
      )}
      {!isFolded &&
        folder.files
          .filter((file) =>
            file.name?.toLowerCase().includes(filterText.toLowerCase())
          )
          .sort((a, b) => b.modified - a.modified)
          .map((file, index) => (
            <File
              key={index}
              file={file}
              formatBytes={formatBytes}
              level={level + 1}
              onGetFile={onGetFile}
              onSelectResource={onSelectResource}
              onSelectInvokeAction={onSelectInvokeAction}
              onGetResourceContents={onGetResourceContents}
              onDeleteFile={onDeleteFile}
              filterText={filterText}
              onAddToSelectionList={onAddToSelectionList}
              selectionList={selectionList}
              onActivateContextMenu={onActivateContextMenu}
            />
          ))}
      {!isFolded &&
        folder.folders.map((subfolder, index) => (
          <Folder
            key={index}
            folder={subfolder}
            formatBytes={formatBytes}
            level={level + 1}
            onGetFile={onGetFile}
            onSelectResource={onSelectResource}
            onSelectInvokeAction={onSelectInvokeAction}
            onDragFileToFolder={onDragFileToFolder}
            onGetResourceContents={onGetResourceContents}
            onDeleteFile={onDeleteFile}
            filterText={filterText}
            onAddToSelectionList={onAddToSelectionList}
            selectionList={selectionList}
            onActivateContextMenu={onActivateContextMenu}
            show={subfolder.name
              .toLowerCase()
              .includes(filterText.toLowerCase())}
          />
        ))}
    </div>
  );
};

// Component to display the project
const Project = ({
  project,
  formatBytes,
  onGetFile,
  onSelectResource,
  onSelectInvokeAction,
  onDragFileToFolder,

  onCopyToContents,
  onGetResourceContents,
  onDeleteFile,
  onAddToSelectionList,
  selectionList,
  filterText,
  onActivateContextMenu,
}) => (
  <div style={{ marginLeft: `${1 * 0}px` }}>
    {/* <h3>Project: {project.name}</h3> */}

    {project.files
      .filter((file) =>
        file.name?.toLowerCase().includes(filterText.toLowerCase())
      )
      //.sort((a, b) => a.modified - b.modified)
      .sort((a, b) => b.modified - a.modified)
      .map((file, index) => (
        <File
          key={index}
          file={file}
          formatBytes={formatBytes}
          level={1}
          onGetFile={onGetFile}
          onSelectResource={onSelectResource}
          onSelectInvokeAction={onSelectInvokeAction}
          onDragFileToFolder={onDragFileToFolder}
          onCopyToContents={onCopyToContents}
          onGetResourceContents={onGetResourceContents}
          onDeleteFile={onDeleteFile}
          filgerText={filterText}
          onAddToSelectionList={onAddToSelectionList}
          selectionList={selectionList}
          onActivateContextMenu={onActivateContextMenu}
        />
      ))}
    {project.folders.map((folder, index) => (
      <Folder
        key={index}
        folder={folder}
        formatBytes={formatBytes}
        level={1}
        onGetFile={onGetFile}
        onSelectResource={onSelectResource}
        onSelectInvokeAction={onSelectInvokeAction}
        onDragFileToFolder={onDragFileToFolder}
        onGetResourceContents={onGetResourceContents}
        onDeleteFile={onDeleteFile}
        filterText={filterText}
        onAddToSelectionList={onAddToSelectionList}
        selectionList={selectionList}
        onActivateContextMenu={onActivateContextMenu}
        show={folder.name.toLowerCase().includes(filterText.toLowerCase())}
      />
    ))}
  </div>
);

export default function UserProjectView({
  project,
  formatBytes,
  onGetFile,
  onDeleteFile,
  selectedUser,
  assetName,
  nodeActions,
  onPerformAction,
  onDragFileToFolder,
  onPolinateResource,
  onCopyToContents,
  onGetResourceContents,
  onMergeResourcesTogether,
  filterText,
}) {
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [resourceForAction, setResourceForAction] = React.useState(null);
  const [selectedActions, setSelectedActions] = React.useState([]);
  const [selectionList, setSelectionList] = React.useState([]);
  const [contextFile, setContextFile] = React.useState(null);
  const [menuPosition, setMenuPosition] = React.useState({ x: 10, y: 10 });

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setContextFile(null); // Close the menu or perform any other action
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the menu
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function getFilenameFromPath(fullPath) {
    if (!fullPath) return "";
    return fullPath.split("/").pop();
  }
  function addSelectedActionToList(action) {
    setSelectedActions([...selectedActions, action]);
  }
  function removeSelectedFromActionList(action) {
    setSelectedActions(
      selectedActions.filter((item) => item.name !== action.name)
    );
  }
  function addResourceToSelectionList(resource) {
    // only add if not already in the list
    if (selectionList.some((item) => item.res === resource)) {
      setSelectionList(selectionList.filter((item) => item.res !== resource));
      return;
    }
    setSelectionList([
      ...selectionList,
      { res: resource, name: getFilenameFromPath(resource) },
    ]);
    //console.log("addResourceToSelectionList", selectionList);
  }
  function removeResourceFromSelection(resource) {
    setSelectionList(selectionList.filter((item) => item.res !== resource.res));
  }
  function invokeActionOnResource(resource, action) {
    console.log("invokeActionOnResource", resource, action);
    onPerformAction(project, action, resource);
  }
  function onContextMenu(event, file) {
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setContextFile(file);
    console.log("onContextMenu", file, event);
  }
  const ContextMenuForResource = () => {
    if (contextFile === null) {
      return null;
    }
    return (
      <div
        ref={menuRef}
        style={{
          position: "fixed",
          top: menuPosition.y,
          left: menuPosition.x - 250,
          backgroundColor: "var(--contextmenucolor)",
          padding: "5px",
          margin: "5px",
          border: "1px solid var(--bordercolor)",
          zIndex: 2000,
          width: "250px",
        }}
      >
        <h5
          className="resource-item"
          onClick={() => {
            onGetFile(contextFile.path + "/" + contextFile.name);
            setContextFile(null);
          }}
        >
          Download
        </h5>

        <hr />
        <h5
          className="resource-item"
          onClick={() => {
            onCopyToContents(contextFile.path + "/" + contextFile.name);
          }}
        >
          Copy To Contents
        </h5>
        <hr />
        <h5
          className="resource-item"
          onClick={() => {
            onPolinateResource(contextFile.path + "/" + contextFile.name);
          }}
        >
          Add To Collection
        </h5>
        <hr />

        <h5
          className="resource-item"
          onClick={() => {
            onDeleteFile(contextFile.path + "/" + contextFile.name);
            setContextFile(null);
          }}
        >
          Delete
        </h5>
        <hr />
        <span>{contextFile.name}</span>
        <br />
        <span>
          modified {timeAgo(contextFile.modified)}
          <br />
        </span>
        <span>{formatBytes(contextFile.size)} in size</span>
      </div>
    );
  };

  if (!project) {
    return (
      <div className="react-tabs">
        <span>No project</span>
      </div>
    );
  }

  return (
    <>
      <div className="react-tabs">
        {selectionList.length > 0 && (
          <div className="commandlist-menu">
            <button
              id="spaces-copy-contents"
              onClick={() =>
                selectionList.forEach((obj) => onCopyToContents(obj.res))
              }
              className="commandlist-menu-button"
            >
              Contents
            </button>
            <button
              id="spaces-polinate"
              onClick={() =>
                selectionList.forEach((obj) => onPolinateResource(obj.res))
              }
              className="commandlist-menu-button"
            >
              Collection
            </button>

            {selectionList.length > 1 && (
              <button
                id="spaces-merge"
                onClick={() =>
                  onMergeResourcesTogether(project.name, selectionList)
                }
                className="commandlist-menu-button"
              >
                Merge
              </button>
            )}
            {/* {selectionList.map((item) => item.name).join(", ")} */}
          </div>
        )}
        {project && (
          <Project
            project={project}
            formatBytes={formatBytes}
            onGetFile={onGetFile}
            nodeActions={nodeActions}
            onSelectResource={setResourceForAction}
            onSelectInvokeAction={invokeActionOnResource}
            onDragFileToFolder={onDragFileToFolder}
            onPolinateResource={onPolinateResource}
            onCopyToContents={onCopyToContents}
            onDeleteFile={onDeleteFile}
            filterText={filterText}
            onGetResourceContents={onGetResourceContents}
            onAddToSelectionList={addResourceToSelectionList}
            selectionList={selectionList}
            onActivateContextMenu={onContextMenu}
          />
        )}
      </div>
      {<ContextMenuForResource />}
      {resourceForAction && (
        <>
          <Modal
            // showClose
            isOpen={resourceForAction !== null}
            onClose={() => {
              setResourceForAction(null);
              setSelectedActions([]);
            }}
          >
            <div style={{ display: "flex" }}>
              {/* , justifyContent: "space-between" */}
              {/* <div
                className="resource-not-important"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TileTowardsMouse text={"🤖"} />
              </div> */}
              <div>
                <span>Invoke Agent on </span>
                {selectionList.length > 1 ? (
                  <>
                    multiple
                    <br />
                    {selectionList.map((item) => (
                      <span>
                        <span
                          className="resource-item"
                          onClick={() => removeResourceFromSelection(item)}
                          style={{
                            cursor: "pointer",
                            fontStyle: "superscript",
                            fontSize: "0.5em",
                            display: "inline-block",
                          }}
                        >
                          ✕
                        </span>

                        <span>{getFilenameFromPath(item.res)}&nbsp;</span>
                      </span>
                    ))}
                  </>
                ) : (
                  <span
                  // style={{ border: "1px dotted", borderRadius: "15px" }}
                  >
                    &nbsp;{getFilenameFromPath(resourceForAction)}&nbsp;
                  </span>
                )}
                <br />

                <NodeActionsDropDown
                  onSelectedAction={setSelectedAction}
                  nodeActions={nodeActions}
                  onPerformAction={onPerformAction}
                  resource={resourceForAction}
                />

                {selectedAction != null && (
                  <>
                    <button
                      onClick={() => addSelectedActionToList(selectedAction)}
                      className="resource-not-important"
                      style={{
                        height: "0.5em",
                        fontSize: "0.8em",
                        justifyContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        display: "flex",
                      }}
                    >
                      + Add To Action Queue
                      {/* {selectedAction.name} */}
                    </button>
                  </>
                )}

                {selectedActions.length > 0 && (
                  <>
                    <hr /> Queue: &nbsp;
                    {selectedActions.map((action, index) => (
                      <span key={index}>
                        <span
                          className="resource-item"
                          onClick={() => removeSelectedFromActionList(action)}
                          style={{
                            cursor: "pointer",
                            fontStyle: "superscript",
                            fontSize: "0.3em",
                          }}
                        >
                          ✕
                        </span>
                        {action.name}&nbsp;
                      </span>
                    ))}
                    <hr />
                  </>
                )}

                <button
                  onClick={() => {
                    if (selectionList.length > 1) {
                      selectionList.forEach((obj) =>
                        onPerformAction(project, selectedAction, obj.res)
                      );
                    } else {
                      if (selectedActions.length > 0) {
                        selectedActions.forEach((action) =>
                          onPerformAction(project, action, resourceForAction)
                        );
                      } else {
                        onPerformAction(
                          project,
                          selectedAction,
                          resourceForAction
                        );
                      }
                    }

                    setResourceForAction(null);
                    setSelectedAction(null);
                  }}
                >
                  Run Agent
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}

      {/* {selectionList.length > 0 && <>Selected: {selectionList.join(", ")}</>} */}

      <Tooltip
        anchorSelect="#spaces-download"
        place="top-start"
        className="resource-not-important"
      >
        <h2>Download</h2> this file. If this is your file, you will not be
        charged, otherwise you will be charged per download per MB and pay
        owner.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-analysis"
        place="top-start"
        className="resource-not-important"
      >
        <h2>Invoke 🤖 Agent</h2> Perform analysis on your text file. You can not
        perform analysis on files that are not yours.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-polinate"
        place="top-start"
        className="resource-not-important"
      >
        <div>
          <h2>Polinate To 🗄 Collection</h2> A collection with project name will
          be created or updated. <br />
          SpaceNode will upload this file to Swarm. You will <br />
          receive a swarm hash and will be able to download the file from Swarm
          network.
        </div>
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-copy-contents"
        place="top-start"
        className="resource-not-important"
      >
        <h2>Publish to 📖 Contents</h2> Data will be available in your public
        Contents section.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-merge"
        place="top-start"
        className="resource-not-important"
      >
        <h2>Merge 🔀</h2> Two or more files will be merged together in same
        order.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-delete"
        place="top-start"
        className="resource-not-important"
      >
        <h2>Delete</h2> This will delete resource from the project.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-menu"
        place="top-start"
        className="resource-not-important"
      >
        <h2>Menu</h2> Open menu with more options.
      </Tooltip>
    </>
  );
}
