import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import convertToCapitalSpaceCase from "../utils/convertToCapitalSpaceCase.js";
import SmartImageCard from "./ui/SmartImageCard.js";
import ActionTaskCard from "./ui/ActionTaskCard.js";

export default function SpaceActions({
  nodeActions,
  onInvokeNodeAction,
  onGetWhatDataIsRequired,
}) {
  const [filterActions, setFilterActions] = useState("");
  const [selectedAction, setSelectedAction] = useState("");

  const whatDataIsRequired = (nodeAction) => {
    console.log("what data do i need for", nodeAction);
    onGetWhatDataIsRequired(nodeAction);
    // invoke Node_WhatDataIsRequired with nodeAction.name over signalr
  };
  const handleDragStart = (e, nodeAction) => {
    console.log("dragging", e.target.id, nodeAction);
    e.dataTransfer.setData("text/plain", JSON.stringify(nodeAction));
  };
  // Step 1: Group nodeActions by 'g'
  const groupedActions = nodeActions.reduce((acc, action) => {
    // Initialize the group array if it doesn't exist
    if (!acc[action.g]) {
      acc[action.g] = [];
    }
    // Add the action to its group
    acc[action.g].push(action);
    return acc;
  }, {});

  // Step 2: Optionally, filter actions within each group based on 'filterActions'
  const filteredGroupedActions = Object.keys(groupedActions).reduce(
    (acc, group) => {
      acc[group] = groupedActions[group].filter((action) =>
        filterActions.length > 0
          ? action.name.toLowerCase().includes(filterActions) ||
            action.description.toLowerCase().includes(filterActions) ||
            action.g.toLowerCase().includes(filterActions)
          : true
      );
      return acc;
    },
    {}
  );

  return (
    <div className="space-actions">
      {/* <h2 className="menu-normal">Actions</h2> */}
      <input
        type="text"
        placeholder="Filter actions"
        onChange={(e) => setFilterActions(e.target.value.toLowerCase())}
        style={{
          height: "0.2rem",
        }}
      />{" "}
      <div>
        {Object.entries(filteredGroupedActions).map(
          ([group, actions], index) => {
            if (actions.length > 0) {
              return (
                <div key={index}>
                  <span className="space-action-group">
                    {convertToCapitalSpaceCase(group)}
                  </span>
                  <br />
                  <div className="space-actions-list">
                    {actions.map((nodeAction, i) => (
                      <span
                        key={i}
                        className="space-action-item"
                        draggable="true"
                        onDragStart={(e) => handleDragStart(e, nodeAction)}
                        onClick={(e) => {
                          toast(
                            <>
                              <strong>
                                Action Characteristic <br />
                              </strong>
                              {convertToCapitalSpaceCase(nodeAction.g)}

                              <div className="product-card__image-container">
                                <SmartImageCard
                                  g={nodeAction.g}
                                  title={nodeAction.name}
                                />
                                <h3>
                                  {convertToCapitalSpaceCase(nodeAction.name)}
                                </h3>
                                {/* </div> */}
                                <p>{nodeAction.description}</p>
                                {nodeAction.sc > 0 && (
                                  <p>Iterations: {nodeAction.sc}</p>
                                )}
                              </div>
                              <button
                                onClick={(e) => whatDataIsRequired(nodeAction)}
                              >
                                What data is required ?
                              </button>
                              <p>
                                <small>
                                  {localStorage.getItem(nodeAction.name, 0)}{" "}
                                  Invocations
                                </small>
                              </p>
                              {/* {console.log("nodeAction", nodeAction)} */}
                            </>
                          );
                        }}
                      >
                        {convertToCapitalSpaceCase(nodeAction.name)}
                      </span>
                    ))}
                  </div>
                </div>
              );
            } else {
              return null;
            }
          }
        )}
        <p>
          ◆ Actions:{" "}
          <strong>
            {Object.values(filteredGroupedActions).reduce(
              (acc, actions) => acc + actions.length,
              0
            )}{" "}
            / {nodeActions.length}{" "}
          </strong>
        </p>
      </div>
    </div>
  );
}
