import React, { useEffect, useState } from "react";
import UserContents from "./UserContents";
import UserCollections from "./UserCollections";
import UserFiles from "./UserFiles";
import UserProjects from "./UserProjects";

import Markdown from "react-markdown";
import UserData from "./UserData";
import SpaceUsers from "./SpaceUsers";
import UserSpacesInfo from "./UserSpacesInfo";
import SpaceActions from "./SpaceActions";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Spinner from "./Spinner";
import obfuscateAddress from "../utils/obfuscateAddress";
import ViewTokenBalances from "./tokens/Tokens";

/**
 * Users.js
 *
 * This is a React component that displays a list of users and their associated data.
 *
 * Props:
 * - address: The address of the current user.
 * - users: An array of user objects.
 * - userContents: An array of user content objects.
 * - onGetUsers: A function to fetch users.
 * - onGetUserContents: A function to fetch the contents of a user.
 * - onGetManifests: A function to fetch the manifests of a user.
 * - manifests: An array of manifest objects.
 * - formatBytes: A function to format bytes.
 * - onReceiveResource: A function to handle when a resource is received.
 * - assetName: The name of the asset.
 * - hash: The hash of the asset.
 *
 * State:
 * - selectedUser: The currently selected user.
 *
 * The component uses two useEffect hooks. The first one is intended to fetch users on component mount,
 * but the fetching function is currently commented out. The second one does nothing but re-renders the
 * component whenever the selectedUser state changes.
 *
 * The component returns a set of tabs. The first tab lists all users. When a user is clicked,
 * the user's contents and manifests are fetched, and the user is set as the selected user.
 * If a user is selected, a second tab appears showing the user's contents.
 * If the selected user has file manifests, a third tab appears showing the manifests.
 */

export default function UserTree({
  nodeId,
  address,
  users,
  nodes,
  onNodeSelected,
  userContents,
  userContent,
  onDeleteContents,

  onGetUsers,
  onGetUserContents,
  onGetUserContent,

  onGetUserCollections,
  onGetUserCollection,
  onGetManifests,

  onGetProjects,
  onGetProject,
  onSetProject,
  onDeleteProject,
  onChangeProjectVisibility,
  onDeleteFile,
  onCreateFolder,
  onAddFileToFolder,
  onDragFileToFolder,
  onRenameFileInFolder,

  userProjects,
  userProject,

  manifests,
  onReceiveManifest,
  onDeleteManifest,

  collections,
  collection,
  onDeleteCollection,

  onGetDataLineage,
  onGetDataProvenance,

  formatBytes,

  selectedUser,
  setSelectedUser,

  userData,
  handleOutstandingPayment,

  spaceUsers,
  onClearSpaceUsers,

  nodeActions,
  onInvokeNodeAction,
  onPolinateResource,
  onCopyToContents,

  onGetResourceContents,
  resourceContents,
  resourceCharacterAnswers,

  onSetResourceContents,
  onMergeResourcesTogether,

  onGetWhatDataIsRequired,

  onCharacterPanel,

  goToUserHome,
  hub,
}) {
  const [userInfoVisible, setUserInfoVisible] = useState(false);
  const [userContentsVisible, setUserContentsVisible] = useState(false);
  const [userCollectionsVisible, setUserCollectionsVisible] = useState(false);
  const [userFilesVisible, setUserFilesVisible] = useState(false);
  const [userProjectVisible, setUserProjectVisible] = useState(false);
  const [filterActions, setFilterActions] = useState("");
  useEffect(() => {
    //onGetUsers("");
  }, []);

  useEffect(() => {}, [selectedUser]);
  const clearVisible = () => {
    setUserInfoVisible(false);
    setUserContentsVisible(false);
    setUserCollectionsVisible(false);
    setUserFilesVisible(false);
    setUserProjectVisible(false);
  };

  const goToUser = (user) => {
    onGetUserContents(user);
    setSelectedUser(user);
    clearVisible();
    setUserContentsVisible(true);
    window.history.pushState(null, null, user.address); // add url to user http://localhost:3000/userAddress
  };
  function convertToCapitalSpaceCase(str) {
    return str.replace(/(?<!^)([A-Z])/g, " $1");
  }

  return (
    // <div className="users-tree">
    <div>
      <div className="container-tree">
        <div className="left-side-tree">
          <div>
            {spaceUsers.length > 0 ? (
              <SpaceUsers
                spaceUsers={spaceUsers}
                address={address}
                formatBytes={formatBytes}
                onReceiveManifest={onReceiveManifest}
                onGetUserCollection={onGetUserCollection}
                collection={collection}
                content={userContent}
                onClearSpaceUsers={onClearSpaceUsers}
                setSelectedUser={setSelectedUser}
                onGetUserContents={onGetUserContents}
                onGetUserContent={onGetUserContent}
              />
            ) : (
              <>
                {selectedUser === null ? (
                  <UserData
                    userData={userData}
                    user={userData.user}
                    onOutstandingPayment={handleOutstandingPayment}
                    formatBytes={formatBytes}
                    selected={false}
                    isHome={true}
                    onGoHome={goToUserHome}
                  />
                ) : (
                  <UserData
                    userData={null}
                    user={selectedUser}
                    onOutstandingPayment={handleOutstandingPayment}
                    formatBytes={formatBytes}
                    selected={true}
                    onClearSelectedUser={() => setSelectedUser(null)}
                    isHome={userData?.user?.address === selectedUser?.address}
                    onGoHome={goToUserHome}
                  />
                )}
              </>
            )}
          </div>

          {spaceUsers.length === 0 && (
            <div>
              {selectedUser != null && (
                <>
                  <h4>
                    <span className={"menu-normal"}>&nbsp;</span>
                    <span
                      id="spaces-projects"
                      className={
                        userProjectVisible ? "menu-selected" : "menu-normal"
                      }
                      onClick={() => {
                        clearVisible();
                        setUserProjectVisible(!userProjectVisible);
                        onGetProjects(selectedUser.address);
                      }}
                    >
                      <span className="resource-not-important">📽</span> Projects
                    </span>
                    {" • "}
                    <span
                      id="spaces-files"
                      className={
                        userFilesVisible ? "menu-selected" : "menu-normal"
                      }
                      onClick={() => {
                        clearVisible();
                        setUserFilesVisible(!userFilesVisible);
                        onGetManifests(selectedUser.address);
                      }}
                    >
                      <span className="resource-not-important">🔐 </span>Vault
                      {/* 📂 Files */}
                    </span>
                    {" • "}
                    <span
                      id="spaces-contents"
                      className={
                        userContentsVisible ? "menu-selected" : "menu-normal"
                      }
                      onClick={() => {
                        clearVisible();
                        setUserContentsVisible(!userContentsVisible);
                        onGetUserContents(selectedUser);
                      }}
                    >
                      <span className="resource-not-important">📖 </span>Content
                    </span>
                    {" • "}
                    <span
                      id="spaces-collections"
                      className={
                        userCollectionsVisible ? "menu-selected" : "menu-normal"
                      }
                      onClick={() => {
                        clearVisible();
                        setUserCollectionsVisible(!userCollectionsVisible);
                        onGetUserCollections(selectedUser);
                      }}
                    >
                      <span className="resource-not-important">🗄 </span>
                      Collections
                    </span>
                  </h4>
                </>
              )}
              {selectedUser != null && (
                <div className="resource-list">
                  {userInfoVisible && (
                    <>
                      {selectedUser.info && (
                        <Markdown>{selectedUser.info}</Markdown>
                      )}{" "}
                    </>
                  )}
                  {userContentsVisible && (
                    <UserContents
                      address={address}
                      selectedUser={selectedUser}
                      contents={userContents}
                      userContent={userContent}
                      onGetUserContent={onGetUserContent}
                      onDeleteContents={onDeleteContents}
                      isHome={userData?.user?.address === selectedUser?.address}
                      onGetDataLineage={onGetDataLineage}
                      onGetDataProvenance={onGetDataProvenance}
                    />
                  )}
                  {userCollectionsVisible && (
                    <UserCollections
                      address={address}
                      collections={collections}
                      collection={collection}
                      user={selectedUser}
                      onGetUserCollection={onGetUserCollection}
                      onDeleteCollection={onDeleteCollection}
                      isHome={userData?.user?.address === selectedUser?.address}
                    />
                  )}
                  {userFilesVisible && (
                    <UserFiles
                      manifests={manifests}
                      address={address}
                      selectedUser={selectedUser}
                      formatBytes={formatBytes}
                      onDeleteManifest={onDeleteManifest}
                      onReceiveManifest={onReceiveManifest}
                      showTitle={false}
                      isHome={userData?.user?.address === selectedUser?.address}
                    />
                  )}
                  {userProjectVisible && (
                    <UserProjects
                      address={address}
                      projects={userProjects}
                      project={userProject}
                      selectedUser={selectedUser}
                      formatBytes={formatBytes}
                      onGetProject={onGetProject}
                      onSetProject={onSetProject}
                      onDeleteProject={onDeleteProject}
                      onDeleteFile={onDeleteFile}
                      onGetFile={onReceiveManifest}
                      showTitle={false}
                      nodeActions={nodeActions}
                      onInvokeAction={onInvokeNodeAction}
                      isHome={userData?.user?.address === selectedUser?.address}
                      onCreateFolder={onCreateFolder}
                      onAddFileToFolder={onAddFileToFolder}
                      onDragFileToFolder={onDragFileToFolder}
                      onRenameFileInFolder={onRenameFileInFolder}
                      onPolinateResource={onPolinateResource}
                      onCopyToContents={onCopyToContents}
                      onChangeProjectVisibility={onChangeProjectVisibility}
                      onGetResourceContents={onGetResourceContents}
                      resourceContents={resourceContents}
                      resourceCharacterAnswers={resourceCharacterAnswers}
                      onSetResourceContents={onSetResourceContents}
                      onMergeResourcesTogether={onMergeResourcesTogether}
                      onCharacterPanel={onCharacterPanel}
                    />
                  )}

                  {/* <ViewTokenBalances walletAddress={address} /> */}
                </div>
              )}
              {selectedUser == null && (
                <UserSpacesInfo hub={hub} goToUserHome={goToUserHome} />
              )}
            </div>
          )}
        </div>

        <div className="right-side-tree">
          {users && users.length > 0 ? (
            <>
              {!userProjectVisible && (
                <div>
                  <h2 className="menu-normal">Users</h2>
                  {/* <h4 className="menu-normal">Accounts</h4> */}
                  {users.map((user, i) => (
                    <div
                      className="resource-item"
                      key={i + user.address}
                      style={{
                        maxWidth: "80%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={(e) => {
                        goToUser(user);
                      }}
                    >
                      {user.nodeId !== nodeId && <>&nbsp;&nbsp;</>}
                      <span
                        className={
                          user.nodeId !== nodeId
                            ? "user-wrong-node"
                            : "user-accesible"
                        }
                      >
                        {user.name.length > 0
                          ? user.name
                          : obfuscateAddress(user.address) + "*"}
                        <small
                          className={user.address === address ? "glitch" : ""}
                        >
                          {user.address === address && " (you)"}
                        </small>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <Spinner />
          )}

          {nodes.length > 0 ? (
            <>
              {!userProjectVisible && (
                <div>
                  <h2 className="menu-normal">Nodes</h2>
                  {nodes.map((node, i) => (
                    <div
                      className="resource-item"
                      key={i + node.address}
                      style={{
                        maxWidth: "80%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={(e) => {
                        //onGetUserContents(node.address);
                        //setSelectedUser(node);
                        onNodeSelected(node);
                        clearVisible();
                        window.history.pushState(null, null, node.address);
                      }}
                    >
                      <span>
                        {node.name.length > 0 ? node.name : node.address + "*"}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <Spinner />
          )}

          {nodeActions.length > 0 ? (
            <>
              {userProjectVisible && (
                <SpaceActions
                  nodeActions={nodeActions}
                  onInvokeNodeAction={onInvokeNodeAction}
                  onGetWhatDataIsRequired={onGetWhatDataIsRequired}
                />
              )}
            </>
          ) : (
            <Spinner />
          )}
          {/* Right side content goes here */}
        </div>
      </div>

      <Tooltip
        anchorSelect="#spaces-projects"
        place="bottom-start"
        className="resource-not-important"
      >
        📽 Project can be private or public. <br />
        Downloaders pay for your public data.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-files"
        place="bottom-start"
        className="resource-not-important"
      >
        🔐 File names are public, contents can be only <br />
        be retrieved via paylink.
        <br />
        Add files by downloading them from Swarm. <br />
        Or upload files to node.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-contents"
        place="bottom-start"
        className="resource-not-important"
      >
        📖 Public markdown to inform <br />
        others about your content.
      </Tooltip>
      <Tooltip
        anchorSelect="#spaces-collections"
        place="bottom-start"
        className="resource-not-important"
      >
        🗄 Public files contained in a list <br />
        with metadata and Swarm references. <br />
        Files are downloaded from Swarm.
      </Tooltip>
    </div>
  );
}
