import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/Cards";

// const ForceDirectedMap = ({ nodes }) => {
//   const svgRef = useRef(null);
//   const [nodes, setNodes] = useState([]);
//   const [tooltipData, setTooltipData] = useState(null);

//   const getRadius = (amount) => {
//     return 20 + Math.log10(amount) * 3;
//   };

//   useEffect(() => {
//     // Initialize nodes with positions in a larger circle
//     const initialNodes = [
//       { id: "Bonding Curve", amount: 841716453811724.0, percentage: 84.17 },
//       { id: "Wallet 1", amount: 58267207008806.0, percentage: 5.82 },
//       { id: "Dev", amount: 43574161163638.0, percentage: 4.35 },
//       { id: "Wallet 2", amount: 30647550202204.0, percentage: 3.06 },
//       { id: "Wallet 3", amount: 12010923126361.0, percentage: 1.2 },
//     ].map((node, i) => ({
//       ...node,
//       // Spread nodes out more initially
//       x: 400 + Math.cos(i * ((2 * Math.PI) / 5)) * 300,
//       y: 300 + Math.sin(i * ((2 * Math.PI) / 5)) * 300,
//       vx: 0,
//       vy: 0,
//       radius: getRadius(node.amount),
//     }));

//     setNodes(initialNodes);
//   }, []);

//   useEffect(() => {
//     if (nodes.length === 0) return;

//     let frameId;
//     const strength = 0.05; // Reduced center attraction
//     const centerX = 400;
//     const centerY = 300;

//     const animate = () => {
//       setNodes((currentNodes) => {
//         return currentNodes.map((node, i) => {
//           let fx = 0;
//           let fy = 0;

//           // Stronger repulsion between nodes
//           currentNodes.forEach((other, j) => {
//             if (i !== j) {
//               const dx = node.x - other.x;
//               const dy = node.y - other.y;
//               const distance = Math.sqrt(dx * dx + dy * dy);
//               const minDistance = node.radius + other.radius;

//               if (distance < minDistance) {
//                 // Strong repulsion when nodes overlap
//                 const force = (2000 * (minDistance - distance)) / minDistance;
//                 fx += (dx / distance) * force;
//                 fy += (dy / distance) * force;
//               } else {
//                 // Normal repulsion when nodes don't overlap
//                 const force = 3000 / (distance * distance);
//                 fx += (dx / distance) * force;
//                 fy += (dy / distance) * force;
//               }
//             }
//           });

//           // Weaker attraction to center
//           const dx = centerX - node.x;
//           const dy = centerY - node.y;
//           const centerDistance = Math.sqrt(dx * dx + dy * dy);
//           const maxDistance = 400; // Maximum distance from center

//           if (centerDistance > maxDistance) {
//             // Strong attraction when too far from center
//             fx += dx * strength * 2;
//             fy += dy * strength * 2;
//           } else {
//             // Normal attraction to center
//             fx += dx * strength;
//             fy += dy * strength;
//           }

//           // Update velocity with more damping
//           const vx = (node.vx + fx * 0.05) * 0.95;
//           const vy = (node.vy + fy * 0.05) * 0.95;

//           // Ensure nodes stay within bounds
//           const newX = Math.max(
//             node.radius,
//             Math.min(800 - node.radius, node.x + vx)
//           );
//           const newY = Math.max(
//             node.radius,
//             Math.min(600 - node.radius, node.y + vy)
//           );

//           return {
//             ...node,
//             x: newX,
//             y: newY,
//             vx,
//             vy,
//           };
//         });
//       });

//       frameId = requestAnimationFrame(animate);
//     };

//     animate();
//     return () => cancelAnimationFrame(frameId);
//   }, [nodes.length]);

//   const formatAmount = (amount) => {
//     if (amount >= 1e12) return `${(amount / 1e12).toFixed(2)}T`;
//     if (amount >= 1e9) return `${(amount / 1e9).toFixed(2)}B`;
//     if (amount >= 1e6) return `${(amount / 1e6).toFixed(2)}M`;
//     return amount.toFixed(2);
//   };

//   const getColor = (percentage) => {
//     const hue = 240; // Blue
//     const saturation = 80;
//     const lightness = 100 - percentage;
//     return `hsl(${hue}, ${saturation}%, ${Math.max(30, lightness)}%)`;
//   };

//   return (
//     <Card className="w-full">
//       <CardHeader>
//         <CardTitle>VOGI Token Distribution</CardTitle>
//       </CardHeader>
//       <CardContent>
//         <div className="relative">
//           <svg
//             ref={svgRef}
//             width="800"
//             height="600"
//             className="w-full h-full"
//             viewBox="0 0 800 600"
//           >
//             {/* Connection lines */}
//             {nodes.map((node, i) => (
//               <line
//                 key={`line-${i}`}
//                 x1={400}
//                 y1={300}
//                 x2={node.x}
//                 y2={node.y}
//                 stroke="#ddd"
//                 strokeWidth="1"
//                 opacity="0.3"
//               />
//             ))}

//             {/* Nodes */}
//             {nodes.map((node, i) => (
//               <g
//                 key={`node-${i}`}
//                 transform={`translate(${node.x},${node.y})`}
//                 onMouseEnter={() =>
//                   setTooltipData({ ...node, x: node.x, y: node.y })
//                 }
//                 onMouseLeave={() => setTooltipData(null)}
//               >
//                 <circle
//                   r={node.radius}
//                   fill={getColor(node.percentage)}
//                   opacity="0.7"
//                   stroke="#fff"
//                   strokeWidth="2"
//                 />
//                 <text
//                   textAnchor="middle"
//                   dy="0.3em"
//                   fill="#fff"
//                   fontSize={node.radius / 3}
//                   className="pointer-events-none"
//                 >
//                   {node.id}
//                 </text>
//               </g>
//             ))}
//           </svg>

//           {/* Tooltip */}
//           {tooltipData && (
//             <div
//               className="absolute bg-white p-2 rounded shadow-lg border border-gray-200 text-sm"
//               style={{
//                 left: `${tooltipData.x + 20}px`,
//                 top: `${tooltipData.y - 20}px`,
//                 transform: "translate(-50%, -100%)",
//               }}
//             >
//               <div className="font-bold">{tooltipData.id}</div>
//               <div>Amount: {formatAmount(tooltipData.amount)}</div>
//               <div>Share: {tooltipData.percentage.toFixed(2)}%</div>
//             </div>
//           )}
//         </div>
//       </CardContent>
//     </Card>
//   );
// };

// export default ForceDirectedMap;

export const ForceDirectedMap = ({ data }) => {
  const svgRef = useRef(null);

  const [nodes, setNodes] = useState([]);
  const [tooltipData, setTooltipData] = useState(null);

  const getRadius = (amount) => {
    return 20 + Math.log10(amount) * 3;
  };

  // const dataRef = useRef(data);

  useEffect(() => {
    console.log(data);
    // is data string ? then convert to object
    if (typeof data === "string") {
      try {
        data = JSON.parse(data);

        console.log(data);
      } catch (e) {
        return;
      }
    }

    if (!data?.nodes) return;

    // Filter out nodes with 0 amount and transform data
    const initialNodes = data.nodes
      .filter((node) => node.amount > 0)
      .slice(0, 30) // Take top 10 holders for visibility
      .map((node, i) => ({
        id:
          node.name ||
          `${node.address.slice(0, 6)}...${node.address.slice(-4)}`,
        amount: node.amount,
        percentage: node.percentage,
        address: node.address,
        // Spread nodes out in a circle initially
        x: 400 + Math.cos(i * ((2 * Math.PI) / 10)) * 300,
        y: 300 + Math.sin(i * ((2 * Math.PI) / 10)) * 300,
        vx: 0,
        vy: 0,
        radius: getRadius(node.percentage), //node.amount),
      }));

    setNodes(initialNodes);
  }, [data]);

  useEffect(() => {
    if (nodes.length === 0) return;

    let frameId;
    const strength = 0.05;
    const centerX = 400;
    const centerY = 300;

    const animate = () => {
      setNodes((currentNodes) => {
        return currentNodes.map((node, i) => {
          let fx = 0;
          let fy = 0;

          // Repulsion between nodes
          currentNodes.forEach((other, j) => {
            if (i !== j) {
              const dx = node.x - other.x;
              const dy = node.y - other.y;
              const distance = Math.sqrt(dx * dx + dy * dy);
              const minDistance = node.radius + other.radius;

              if (distance < minDistance) {
                // Strong repulsion when nodes overlap
                const force = (500 * (minDistance - distance)) / minDistance;
                fx += (dx / distance) * force;
                fy += (dy / distance) * force;
              } else {
                // Normal repulsion when nodes don't overlap
                const force = 3000 / (distance * distance);
                fx += (dx / distance) * force;
                fy += (dy / distance) * force;
              }
            }
          });

          // Center attraction
          const dx = centerX - node.x;
          const dy = centerY - node.y;
          const centerDistance = Math.sqrt(dx * dx + dy * dy);
          const maxDistance = 400;

          if (centerDistance > maxDistance) {
            fx += dx * strength * 2;
            fy += dy * strength * 2;
          } else {
            fx += dx * strength;
            fy += dy * strength;
          }

          // Update velocity
          const vx = (node.vx + fx * 0.05) * 0.95;
          const vy = (node.vy + fy * 0.05) * 0.95;

          // Keep nodes within bounds
          const newX = Math.max(
            node.radius,
            Math.min(800 - node.radius, node.x + vx)
          );
          const newY = Math.max(
            node.radius,
            Math.min(600 - node.radius, node.y + vy)
          );

          return {
            ...node,
            x: newX,
            y: newY,
            vx,
            vy,
          };
        });
      });

      frameId = requestAnimationFrame(animate);
    };

    animate();
    return () => cancelAnimationFrame(frameId);
  }, [nodes.length]);

  const formatAmount = (amount) => {
    if (amount >= 1e12) return `${(amount / 1e12).toFixed(2)}T`;
    if (amount >= 1e9) return `${(amount / 1e9).toFixed(2)}B`;
    if (amount >= 1e6) return `${(amount / 1e6).toFixed(2)}M`;
    return amount.toFixed(2);
  };

  const getColor = (percentage) => {
    const hue = 240;
    const saturation = 80;
    const lightness = 100 - percentage;
    return `hsl(${hue}, ${saturation}%, ${Math.max(30, lightness)}%)`;
  };
  if (data?.nodes?.length === 0) {
    return <div>Not BubbleMap</div>;
  }

  return (
    <div className="relative">
      <svg
        ref={svgRef}
        width="800"
        height="600"
        className="w-full h-full"
        viewBox="0 0 800 600"
      >
        {/* Connection lines */}
        {nodes.map((node, i) => (
          <line
            key={`line-${i}`}
            x1={400}
            y1={300}
            x2={node.x}
            y2={node.y}
            stroke="#ddd"
            strokeWidth="1"
            opacity="0.3"
          />
        ))}

        {/* Nodes */}
        {nodes.map((node, i) => (
          <g
            key={`node-${i}`}
            transform={`translate(${node.x},${node.y})`}
            onMouseEnter={() =>
              setTooltipData({ ...node, x: node.x, y: node.y })
            }
            onMouseLeave={() => setTooltipData(null)}
          >
            <circle
              r={node.radius}
              fill={getColor(node.percentage)}
              opacity="0.7"
              stroke="#fff"
              strokeWidth="2"
            />
            <text
              textAnchor="middle"
              dy="0.3em"
              fill="#fff"
              fontSize={node.radius / 3}
              className="pointer-events-none"
            >
              {node.id}
            </text>
          </g>
        ))}
      </svg>

      {/* Tooltip */}
      {tooltipData && (
        <div
          className="absolute bg-white p-2 rounded shadow-lg border border-gray-200 text-sm"
          style={{
            left: `${tooltipData.x + 20}px`,
            top: `${tooltipData.y - 20}px`,
            transform: "translate(-50%, -100%)",
          }}
        >
          <div className="font-bold">{tooltipData.id}</div>
          <div className="text-xs overflow-hidden text-ellipsis">
            {tooltipData.address}
          </div>
          <div>Amount: {formatAmount(tooltipData.amount)}</div>
          <div>Share: {tooltipData.percentage.toFixed(2)}%</div>
        </div>
      )}
    </div>
  );
};

// // Example usage with default export
// export default () => {
//   const data = {
//     chain: "sol",
//     symbol: "VOGI",
//     nodes: [
//       {
//         address: "8HNM1EpBEqouj6qm2EgX9bvpCN1oCryYQ8SGPiN2exJn",
//         amount: 841716453811724.0,
//         name: "Bonding Curve",
//         percentage: 84.1716453811724,
//       },
//       {
//         address: "6uzNKVDbTprfsPYGtJJCnPem8drsExvpkFepgAdwoVDF",
//         amount: 58267207008806.0,
//         percentage: 5.8267207008806,
//       },
//       {
//         address: "E3RTEJQ8PmLvpgqZNJwnFam4L7Xjo8KNxeGUvAwkKpmg",
//         amount: 43574161163638.0,
//         name: "Dev",
//         percentage: 4.3574161163638,
//       },
//       {
//         address: "3x9ahkLhvYwdjYsVqQDtUpRQbmZX4MUbAiRvpCYxoRNp",
//         amount: 30647550202204.0,
//         percentage: 3.0647550202204,
//       },
//       {
//         address: "RggV3niJwABZ7pksV8hna65fo8SPMXD5LAxQTfkbNx4",
//         amount: 12010923126361.0,
//         percentage: 1.2010923126361,
//       },
//     ],
//   };

//   return <ForceDirectedMap data={data} />;
// };
