import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import UserProjectView from "./UserProjectView";
import { useDropzone } from "react-dropzone";
import { Tooltip } from "react-tooltip";
import Modal from "./Modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import convertToCapitalSpaceCase from "../utils/convertToCapitalSpaceCase";
import Markdown from "react-markdown";
import MarkmapView from "./MarkmapView";

import * as XLSX from "xlsx";
import MyExcelGridComponent from "./MyExcelGridComponent";
import MyCSVGridComponent from "./MyCSVGridComponent";
import JsonView from "@uiw/react-json-view";
import { ForceDirectedMap } from "./ForceDirectedMap";

// TODO, service will return dynamic object with properties,
//  display them all in tabs, with names captial case

function MyDropzone() {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log("dropzone read", binaryStr);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );
}

function AddProject({ onAddFolder }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");
  return (
    <div style={{ right: "0px", bottom: "10px", position: "relative" }}>
      {!isOpen && <span onClick={() => setIsOpen(!isOpen)}>[+] Project</span>}
      {isOpen && <span onClick={() => setIsOpen(!isOpen)}>[-]</span>}
      {isOpen && (
        <>
          <input
            type="text"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="Project Name"
          />
          <button
            onClick={() => {
              onAddFolder(folderName);
              setIsOpen(false);
            }}
          >
            Create
          </button>
        </>
      )}
    </div>
  );
}

function AddFileToFolder({ folderName, onAddFileToFolder }) {
  const [isOpen, setIsOpen] = React.useState(false);
  //const [folderName, setFolderName] = React.useState("");
  const [filename, setFilename] = React.useState("");
  const [contents, setContents] = React.useState("");
  return (
    <span>
      {!isOpen && (
        <span onClick={() => setIsOpen(!isOpen)} id="project-add-file">
          {" "}
          [+]
        </span>
      )}
      {isOpen && (
        <span onClick={() => setIsOpen(!isOpen)} id="project-remove-file">
          {" "}
          [-]
        </span>
      )}
      {isOpen && (
        <>
          <MyDropzone />
          {/* <input
            type="text"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="File Name"
          /> */}
          <input
            type="text"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            placeholder="File Name"
          />
          <br />
          <textarea
            type="text"
            rows="3"
            value={contents}
            onChange={(e) => setContents(e.target.value)}
            placeholder="Contents"
            style={{ width: "90%", height: "5em" }}
          />
          <button
            onClick={() => {
              onAddFileToFolder(folderName, filename, contents);
              setIsOpen(false);
            }}
          >
            Add File
          </button>
        </>
      )}
    </span>
  );
}

export default function UserProject({
  address,
  projects,
  project,
  formatBytes,
  selectedUser,
  onGetProject,
  onSetProject,
  onGetFile,
  onDeleteProject,
  onDeleteFile,
  showTitle = true,
  assetName,
  nodeActions,
  onInvokeAction,
  onDragFileToFolder,
  isHome,
  onCreateFolder,
  onAddFileToFolder,
  onRenameFileInFolder,
  onPolinateResource,
  onCopyToContents,
  onGetResourceContents,
  resourceContents,
  resourceCharacterAnswers,
  onSetResourceContents,
  onChangeProjectVisibility,
  onMergeResourcesTogether,
  onCharacterPanel,
}) {
  const [filterText, setFilterText] = React.useState("");
  const [filterFilesText, setFilterFilesText] = React.useState("");
  const [newFilename, setNewFilename] = React.useState("");
  const [newResourcePath, setNewResourcePath] = React.useState("");
  const [isFilenameChanged, setIsFilenameChanged] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(false);
  const [selectedText, setSelectedText] = React.useState("");
  const [menuPosition, setMenuPosition] = React.useState({ x: 0, y: 0 });

  useEffect(() => {}, []);
  // function to copy to clipboard
  const performAction = (project, action, resource) => {
    if (action === null) {
      toast("Please select an action");
      return;
    }
    // console.log("performAction project", project, "performAction", resource, "action", action);
    toast(
      <>
        Invoking Agent on
        <br />
        <h3>{convertToCapitalSpaceCase(action.name)}</h3>
        {action.description}
        {/* <strong> {action.providerNodeId}</strong> */}
      </>
    );

    onInvokeAction(project, action, resource);
  };
  useEffect(() => {
    const resourceFileName = resourceContents?.name;
    const filename = resourceFileName?.split("/").pop();
    setNewFilename(filename);
    const respath = resourceFileName?.replace(selectedUser.address, "");
    setNewResourcePath(respath);
  }, [resourceContents]);

  useEffect(() => {
    //console.log("isFilenameChanged", isFilenameChanged);
  }, [isFilenameChanged]);

  const changeFilename = (e) => {
    //console.log("changeFilename", e.target.value, isFilenameChanged);
    setNewFilename(e.target.value);

    const resourceFileName = resourceContents?.name;
    const original_filename = resourceFileName?.split("/").pop();
    setIsFilenameChanged(e.target.value !== original_filename);
  };

  const onViewResource = (resource) => {
    onGetResourceContents(resource);
    onCharacterPanel(resource);
  };

  const isExcelFile = (filename) => {
    return filename.endsWith(".xls") || filename.endsWith(".xlsx");
  };
  const isCSVFile = (filename) => {
    return filename.endsWith(".csv") || filename.endsWith(".CSV");
  };
  const isTextOrMarkdownFile = (filename) => {
    //all is good as long as its not an excel file or csv file
    return !isExcelFile(filename) && !isCSVFile(filename);
  };
  const convertToJSONObject = (data) => {
    try {
      //debugger;
      return JSON.parse(data);
    } catch (e) {
      return { error: e.message };
    }
  };

  const readCsvFile = (filename, stringData) => {
    const csvRows = stringData.split("\n");
    const gridData = csvRows.map((row) => row.split(","));
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    console.log(`Selected text: ${window.getSelection().toString()}`);

    setSelectedText(selection.toString());

    const boundingRect = selection.getRangeAt(0).getBoundingClientRect();
    // console.log(boundingRect);
    // set menu position on right bottom side of bounding rect
    setMenuPosition({
      x: boundingRect.x,
      y: boundingRect.bottom,
    });
  };

  // context menu on right side of bounding rect
  const ContextMenuForText = () => {
    if (selectedText.length < 1) {
      return null;
    }
    return (
      <div
        style={{
          position: "absolute",
          top: menuPosition.y,
          left: menuPosition.x,
          backgroundColor: "var(--contextmenucolor)",
          padding: "5px",
          margin: "5px",
          border: "1px solid var(--bordercolor)",
          zIndex: 2000,
        }}
      >
        {/* <h4>Selected Text</h4> */}
        {/* <button onClick={() => console.log("copy to clipboard")}>⎘</button> */}
        <p>{selectedText}</p>
      </div>
    );
  };

  const handleDropFileOnProject = (e, project) => {
    e.preventDefault();
    const dropedData = e.dataTransfer.getData("text/plain"); // Handle the dropped item, e.g., move or copy it to the current file's location
    const file = JSON.parse(dropedData);
    console.log(
      "handleDropFileOnProject",
      file.path + "/" + file.name,
      project.owner + "/" + project.name
    );
    onDragFileToFolder(
      file.path + "/" + file.name,
      project.owner + "/" + project.name
    );
    //onSelectInvokeAction(file.path + "/" + file.name, nodeAction);
  };
  const handleDragOverProject = (e) => {
    e.preventDefault(); // Necessary to allow for dropping
    //setIsDragOver(true); // Highlight the row
    //console.log("over folder", e.target.id);
  };

  //return <div onMouseUp={handleMouseUp}>Text</div>;

  // set newFilename

  return (
    <>
      <input
        type="text"
        value={filterText}
        className="filter-search"
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Filter projects..."
      />
      <div className="react-tabs" style={{ width: "100%" }}>
        {isHome && (
          <div>
            <AddProject
              onAddFolder={(folderName) => {
                onCreateFolder(folderName);
                onGetProject(address, folderName);
              }}
            />
          </div>
        )}
        <Tooltip
          anchorSelect="#spaces-project-delete"
          place="top-start"
          className="resource-not-important"
        >
          <h3>Delete Project</h3>This will delete your project.
        </Tooltip>
        <Tooltip
          anchorSelect="#project-add-file"
          place="top-start"
          className="resource-not-important"
        >
          <h3>Add File</h3> Opens a inline dialog to create new file or upload
          new file
        </Tooltip>
        <Tooltip
          anchorSelect="#project-remove-file"
          place="top-start"
          className="resource-not-important"
        >
          <h3>Close File</h3> Closes add dialog
        </Tooltip>
        <Tooltip
          anchorSelect="#spaces-project-public"
          place="top-start"
          className="resource-not-important"
        >
          <h3>Public Project</h3>Contents are public, users can pay-to-download
          contents. You can change project to private 🔒.
        </Tooltip>
        <Tooltip
          anchorSelect="#spaces-project-private"
          place="top-start"
          className="resource-not-important"
        >
          <h3>Private</h3>No one can see or access this content. Change project
          visibility to public 🌐.
        </Tooltip>
        {projects.length > 0 && (
          <>
            {showTitle && <h2>Projects {selectedUser.name} </h2>}
            <div>
              {projects
                .filter(
                  (projectItem) =>
                    projectItem.name.includes(filterText) ||
                    projectItem.name === project?.name
                )
                .map((projectItem, index) => (
                  <div key={index}>
                    <span
                      style={{
                        cursor: "pointer",
                        position: "sticky",
                        top: "3vh",
                        zIndex: 100,
                        backgroundColor: "var(--backgroundcolor)",
                      }}
                    >
                      <small>
                        {projectItem.public === true ? (
                          <span
                            id="spaces-project-public"
                            onClick={() =>
                              onChangeProjectVisibility(
                                address,
                                projectItem.name,
                                false
                              )
                            }
                          >
                            🌐
                          </span>
                        ) : (
                          <span
                            id="spaces-project-private"
                            onClick={() =>
                              onChangeProjectVisibility(
                                address,
                                projectItem.name,
                                true
                              )
                            }
                          >
                            🔒
                          </span>
                        )}
                      </small>
                      {projectItem.name === project?.name ? (
                        <strong
                          onClick={(e) => onSetProject(null)}
                          onDrop={(e) =>
                            handleDropFileOnProject(e, projectItem)
                          }
                          onDragOver={(e) => handleDragOverProject(e)}
                        >
                          🗂️ {projectItem.name}
                        </strong>
                      ) : (
                        <span
                          onClick={(e) =>
                            onGetProject(selectedUser.address, projectItem.name)
                          }
                          onDrop={(e) =>
                            handleDropFileOnProject(e, projectItem)
                          }
                          onDragOver={(e) => handleDragOverProject(e)}
                        >
                          📽 {projectItem.name}
                        </span>
                      )}
                    </span>

                    <span style={{ float: "right" }} id="spaces-project-delete">
                      {address === projectItem.owner && (
                        <span
                          className="resource-item"
                          style={{ cursor: "not-allowed" }}
                        >
                          <small onClick={() => onDeleteProject(projectItem)}>
                            🗑
                          </small>
                          &nbsp;
                        </span>
                      )}
                    </span>
                    {projectItem.name === project?.name && (
                      <>
                        <AddFileToFolder
                          onAddFileToFolder={onAddFileToFolder}
                          folderName={project.name}
                        />
                        <UserProjectView
                          filterText={filterText}
                          project={project}
                          formatBytes={formatBytes}
                          selectedUser={selectedUser}
                          assetName={assetName}
                          onGetFile={onGetFile}
                          onPerformAction={performAction}
                          onDragFileToFolder={onDragFileToFolder}
                          nodeActions={nodeActions}
                          onAddFileToFolder={onAddFileToFolder}
                          onPolinateResource={onPolinateResource}
                          onCopyToContents={onCopyToContents}
                          onDeleteFile={onDeleteFile}
                          onGetResourceContents={onViewResource}
                          onMergeResourcesTogether={onMergeResourcesTogether}
                        />
                      </>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}

        {/* update data of file modal dialog */}
        {resourceContents != null && (
          <Modal
            isOpen={resourceContents != null}
            onClose={() => {
              onSetResourceContents(null);
              onCharacterPanel(null);
              setSelectedText("");
            }}
          >
            <div
              style={{ width: "85vw", height: "75vh", margin: "10px" }}
              onMouseUp={handleMouseUp}
            >
              <span className="enlarge-shrink-text">{newResourcePath}</span>
              <Tabs defaultIndex={0}>
                <TabList>
                  <Tab>Text</Tab>
                  <Tab>MindMap</Tab>
                  <Tab>Sheet</Tab>
                  <Tab>JSON</Tab>
                  <Tab>BubbleMap</Tab>
                  <Tab>Edit</Tab>
                  {resourceCharacterAnswers.length > 0 && (
                    <Tab>
                      Answers{" "}
                      <span style={{ fontSize: "0.3em" }}>
                        {resourceCharacterAnswers.length}
                      </span>
                    </Tab>
                  )}
                </TabList>

                <TabPanel>
                  <div
                    style={{
                      width: "98%",
                      height: "90%",
                      maxHeight: "70vh",
                      overflow: "auto",
                    }}
                  >
                    <Markdown>
                      {typeof resourceContents?.data === "string"
                        ? resourceContents.data
                        : JSON.stringify(resourceContents.data)}
                    </Markdown>
                    {resourceCharacterAnswers.map((answer, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "var(--heavyhighlitecolor)",
                        }}
                      >
                        <strong>{answer.codename}</strong>
                        <div
                          key={index}
                          style={{
                            backgroundColor: "#ffffff0a",
                            padding: "5px",
                          }}
                        >
                          <Markdown>{answer.answer}</Markdown>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    style={{
                      width: "75vw",
                      height: "72vh",
                      maxHeight: "70vh",
                      position: "fixed",
                    }}
                  >
                    <MarkmapView
                      //value={contents[key]}
                      value={resourceContents.data}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    style={{
                      // width: "78vw",
                      height: "72vh",
                      maxHeight: "70vh",
                      overflowY: "auto",
                    }}
                  >
                    <MyCSVGridComponent stringData={resourceContents.data} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    style={{
                      // width: "78vw",
                      height: "72vh",
                      maxHeight: "70vh",
                      overflowY: "auto",
                    }}
                  >
                    <JsonView
                      value={convertToJSONObject(resourceContents.data)}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <ForceDirectedMap data={resourceContents.data} />
                </TabPanel>
                <TabPanel>
                  <>
                    <input
                      type="text"
                      value={newFilename}
                      //defaultValue={newFilename}
                      onChange={(e) => changeFilename(e)}
                      style={{ width: "40vw" }}
                    />
                    {isFilenameChanged === true && (
                      <button
                        onClick={() => {
                          const path = resourceContents?.name.split("/");
                          const filename = path.pop();
                          var folderPath = resourceContents?.name.replace(
                            filename,
                            ""
                          );
                          folderPath = folderPath.replace(
                            selectedUser.address,
                            ""
                          );
                          folderPath = folderPath.substring(1);
                          onRenameFileInFolder(
                            folderPath,
                            resourceContents?.name,
                            newFilename
                          );
                        }}
                      >
                        Rename File {isFilenameChanged}
                      </button>
                    )}
                    <textarea
                      style={{ width: "95%", height: "55vh" }}
                      value={resourceContents?.data}
                      onChange={(e) =>
                        onSetResourceContents({
                          name: resourceContents.name,
                          data: e.target.value,
                        })
                      }
                    />
                    <br />
                    <button
                      onClick={() => {
                        const path = resourceContents?.name.split("/"); // extract path from resourceContents.name
                        const filename = path.pop();

                        var folderPath = resourceContents?.name.replace(
                          filename,
                          ""
                        );
                        folderPath = folderPath.replace(
                          selectedUser.address,
                          ""
                        );
                        // remove first slash
                        folderPath = folderPath.substring(1);
                        //console.log("addFileToFolder", folderPath, filename);

                        onAddFileToFolder(
                          folderPath,
                          filename,
                          resourceContents.data,
                          true
                        );
                        //onSetResourceContents(null);
                        //onCharacterPanel(null);
                      }}
                    >
                      Update contents
                    </button>
                  </>
                </TabPanel>
                {resourceCharacterAnswers.length > 0 && (
                  <TabPanel>
                    <div
                      style={{
                        width: "99%",
                        height: "90%",
                        maxHeight: "70vh",
                        overflow: "auto",
                      }}
                    >
                      {resourceCharacterAnswers.map((answer, index) => (
                        <div
                          key={index}
                          style={{
                            backgroundColor: "var(--heavyhighlitecolor)",
                          }}
                        >
                          <strong>{answer.codename}</strong>
                          <div
                            key={index}
                            style={{
                              backgroundColor: "#ffffff0a",
                              padding: "5px",
                            }}
                          >
                            <Markdown>{answer.answer}</Markdown>
                          </div>
                        </div>
                      ))}
                    </div>
                  </TabPanel>
                )}
              </Tabs>
              <ContextMenuForText />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
